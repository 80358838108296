<template>
  <div class="contractlist">
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="params.name" placeholder="输入操作员姓名">
            <template slot="pre">操作员姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" left @click="currentChange(1)">
            搜索
            <template slot="preImage">
              <img src="../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="21815" class="offset" @click="addAdmin">
            新增
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="
            $exported(
              '/user/export',
              {
                ...params,
                pageNum: pageNum,
                pageSize: pageSize,
              },
              '操作员列表.xlsx'
            )
            ">
            <span>导出</span>
            <template slot="preImage">
              <img src="../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>

      <!--        <MyButton class="offset"  @click="settingVisible=true">联系人设置-->
      <!--          <template slot="preImage">-->
      <!--            <img src="@/unit/img/edit.png" alt="">-->
      <!--          </template>-->
      <!--        </MyButton>-->

      <!-- table -->
      <div class="public-table">
        <el-table :data="tableList" v-loading="loading" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="操作姓名" prop="name"></el-table-column>
          <el-table-column label="联系电话" prop="mobile"></el-table-column>
          <el-table-column label="登录账号" prop="market_worker_no"></el-table-column>
          <el-table-column label="角色" prop="management.management_name"></el-table-column>
          <el-table-column label="市场管理联系人员">
            <template #default="scope">
              <el-switch v-model="scope.row.property_status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949"
                @change="switchChange(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="create_at"></el-table-column>
          <el-table-column label="最近登录时间" prop="last_login_time"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <div class="slot-box">
                <pop tips="编辑" :accessId="39295" @myclick="editAdmin(scope.row)" pop-right>
                  <img src="../../unit/img/edit.png" class="icon" alt />
                </pop>
                <pop tips="重置" :accessId="90468" @myclick="resetPass(scope.row.user_id)" pop-right>
                  <img src="@/assets/img/icon/chongzhi.png" class="icon" alt />
                </pop>
                <pop tips="删除" :accessId="23025" @myclick="del(scope.row)">
                  <img src="@/assets/img/icon/deleted.png" class="icon" alt />
                </pop>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination :total="pageTotal" next-text="下一页" prev-text="上一页" :current-page="pageNum" :page-size="pageSize"
          @current-change="currentChange" layout="total,prev,pager,next"></el-pagination>
      </div>
    </div>
    <el-dialog title="新增/编辑" :visible.sync="dialogVisible" width="580px" @close="handleClose"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-col :span="14">
            <el-input v-model="ruleForm.name" class="public-input" placeholder="请输入姓名"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-col :span="14">
            <el-input v-model="ruleForm.mobile" class="public-input" placeholder="请输入联系电话"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="角色" prop="management_id">
          <el-col :span="14">
            <el-select v-model="ruleForm.management_id" class="public-input" placeholder="请选择角色">
              <el-option :label="item.management_name" :value="item.management_id" v-for="item in roleData"
                :key="item.management_id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="头像">
          <img :src="ruleForm.avatar" v-if="ruleForm.avatar" style="width: 250px; height: 200px; margin-bottom: 20px" />
          <el-upload class="upload-demo" ref="upload" action :on-success="uploadSuccess" :on-error="uploadError"
            :show-file-list="false" :on-change="handlePreview" :auto-upload="false">
            <el-button icon="el-icon-upload2" type="primary">上传头像</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="submitForm('ruleForm')"></MyButton>
      </span>
    </el-dialog>
    <el-dialog title="联系人设置" :visible.sync="settingVisible" width="580px" :close-on-click-modal="false">
      <div v-for="(ite, index) in peoples" :key="index" style="margin-bottom: 10px">
        <span>联系人{{ index + 1 }}：</span>
        <el-select v-model="ite.id" class="public-input" placeholder="请选择">
          <el-option :label="item.name" :value="item.user_id" v-for="item in tableList" :key="item.user_id"></el-option>
        </el-select>
        <MyButton type="warning" title="删除" v-if="index != 0" left @click="deletePeople(index)"></MyButton>
        <MyButton title="添加" left v-if="index == peoples.length - 1" @click="addPeople"></MyButton>
      </div>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="settingVisible = false"></MyButton>
        <MyButton title="确认" type="primary"></MyButton>
      </span>
    </el-dialog>
    <!--    根据页面 用来构造权限树 数据结构 勿删-->
    <!--            <div>-->
    <!--              <el-tree-->
    <!--                  :data="data"-->
    <!--                  show-checkbox-->
    <!--                  node-key="id"-->
    <!--                  default-expand-all-->
    <!--                  :props="defaultProps"-->
    <!--                  :expand-on-click-node="false">-->
    <!--              <span class="custom-tree-node" slot-scope="{ node, data }">-->
    <!--                <span>{{ node.label }}</span>-->
    <!--                <span>-->
    <!--                  <el-button-->
    <!--                      type="text"-->
    <!--                      size="mini"-->
    <!--                      @click="() => append(data)">-->
    <!--                    Append-->
    <!--                  </el-button>-->
    <!--                  <el-button-->
    <!--                      type="text"-->
    <!--                      size="mini"-->
    <!--                      @click="() => remove(node, data)">-->
    <!--                    Delete-->
    <!--                  </el-button>-->
    <!--                </span>-->
    <!--              </span>-->
    <!--              </el-tree>-->
    <!--              <div @click="printf">打印数据</div>-->
    <!--            </div>-->
  </div>
</template>
<script>
let id = 100;
export default {
  data () {
    return {
      data: [
        {
          name: "物业管理",
          id: 1,
          child: [
            {
              id: 100,
              name: "物业概览",
              child: [],
            },
            {
              id: 101,
              name: "商户管理",
              child: [
                {
                  id: 102,
                  name: "新增商户",
                  child: [],
                },
                {
                  id: 103,
                  name: "大规模入住",
                  child: [],
                },
                {
                  id: 104,
                  name: "同步商户数据",
                  child: [],
                },
                {
                  id: 105,
                  name: "导出",
                  child: [],
                },
                {
                  id: 106,
                  name: "修改",
                  child: [],
                },
                {
                  id: 107,
                  name: "店铺二维码",
                  child: [],
                },
                {
                  id: 108,
                  name: "删除",
                  child: [],
                },
                {
                  id: 109,
                  name: "详情",
                  child: [],
                },
              ],
            },
            {
              id: 110,
              name: "合同管理",
              child: [
                {
                  id: 111,
                  name: "合同概览",
                  child: [],
                },
                {
                  id: 112,
                  name: "合同列表",
                  child: [
                    {
                      id: 115,
                      name: "新增商户合同",
                      child: [],
                    },
                    {
                      id: 117,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 119,
                      name: "增加合同",
                      child: [],
                    },
                    {
                      id: 100,
                      name: "编辑",
                      child: [],
                    },
                    {
                      id: 101,
                      name: "删除",
                      child: [],
                    },
                    {
                      id: 102,
                      name: "审批",
                      child: [],
                    },
                    {
                      id: 103,
                      name: "详情",
                      child: [
                        {
                          id: 104,
                          name: "发送续签通知",
                          child: [],
                        },
                        {
                          id: 105,
                          name: "编辑续签合同",
                          child: [],
                        },
                        {
                          id: 106,
                          name: "上传续签合同",
                          child: [],
                        },
                        {
                          id: 107,
                          name: "终止合同",
                          child: [],
                        },
                        {
                          id: 108,
                          name: "查看合同文件",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 113,
                  name: "历史合同",
                  child: [
                    {
                      id: 121,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 122,
                      name: "详情",
                      child: [],
                    },
                  ],
                },
                {
                  id: 114,
                  name: "合同设置",
                  child: [
                    {
                      id: 123,
                      name: "模板设置",
                      child: [
                        {
                          id: 125,
                          name: "新增合同模板",
                          child: [],
                        },
                        {
                          id: 126,
                          name: "编辑合同",
                          child: [],
                        },
                        {
                          id: 127,
                          name: "编辑编号规则",
                          child: [],
                        },
                        {
                          id: 128,
                          name: "电子章上传",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 124,
                      name: "功能设置",
                      child: [
                        {
                          id: 129,
                          name: "线上合同消息设置",
                          child: [],
                        },
                        {
                          id: 130,
                          name: "合同到期预警设置",
                          child: [],
                        },
                        {
                          id: 131,
                          name: "合同到期消息通知设置",
                          child: [],
                        },
                        {
                          id: 132,
                          name: "线下合同签订地址设置",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: 133,
              name: "商铺管理",
              child: [
                {
                  id: 134,
                  name: "商铺出租概览",
                  child: [
                    {
                      id: 138,
                      name: "导出",
                      child: [],
                    },
                  ],
                },
                {
                  id: 135,
                  name: "商铺列表",
                  child: [
                    {
                      id: 140,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 142,
                      name: "历史出租信息",
                      child: [],
                    },
                  ],
                },
                {
                  id: 136,
                  name: "商铺类型",
                  child: [
                    {
                      id: 143,
                      name: "新增商铺类型",
                      child: [],
                    },
                    {
                      id: 144,
                      name: "编辑",
                      child: [],
                    },
                    {
                      id: 145,
                      name: "删除",
                      child: [],
                    },
                  ],
                },
                {
                  id: 137,
                  name: "商铺设置",
                  child: [
                    {
                      id: 146,
                      name: "新增商铺",
                      child: [],
                    },
                    {
                      id: 147,
                      name: "批量设置",
                      child: [],
                    },
                    {
                      id: 148,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 149,
                      name: "编辑",
                      child: [],
                    },
                    {
                      id: 150,
                      name: "删除",
                      child: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 151,
              name: "收费管理",
              child: [
                {
                  id: 155,
                  name: "收费概览",
                  child: [
                    {
                      id: 161,
                      name: "收费情况表",
                      child: [
                        {
                          id: 163,
                          name: "导出",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 162,
                      name: "缴费户数统计表",
                      child: [
                        {
                          id: 164,
                          name: "导出",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 156,
                  name: "商户账单",
                  child: [
                    {
                      id: 165,
                      name: "基本账单",
                      child: [
                        {
                          id: 167,
                          name: "设置",
                          child: [],
                        },
                        {
                          id: 168,
                          name: "导出",
                          child: [],
                        },
                        {
                          id: 169,
                          name: "详情",
                          child: [
                            {
                              id: 100,
                              name: "导出",
                              child: [],
                            },
                            {
                              id: 101,
                              name: "退款",
                              child: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: 166,
                      name: "日常账单",
                      child: [
                        {
                          id: 170,
                          name: "批量商铺数据初始化",
                          child: [],
                        },
                        {
                          id: 171,
                          name: "商户商铺初始化",
                          child: [],
                        },
                        {
                          id: 172,
                          name: "手工抄表数据批量导入",
                          child: [],
                        },
                        {
                          id: 173,
                          name: "导出",
                          child: [],
                        },
                        {
                          id: 174,
                          name: "出账记录",
                          child: [
                            {
                              id: 100,
                              name: "导出",
                              child: [],
                            },
                            {
                              id: 101,
                              name: "设置",
                              child: [],
                            },
                            {
                              id: 102,
                              name: "历史确认信息",
                              child: [],
                            },
                            {
                              id: 103,
                              name: "批量确认",
                              child: [],
                            },
                            {
                              id: 104,
                              name: "确认出账",
                              child: [],
                            },
                            {
                              id: 105,
                              name: "详细数据",
                              child: [],
                            },
                            {
                              id: 109,
                              name: "数据异常确认",
                              child: [],
                            },
                          ],
                        },
                        {
                          id: 175,
                          name: "详情",
                          child: [
                            {
                              id: 102,
                              name: "退款",
                              child: [],
                            },
                            {
                              id: 103,
                              name: "缴费记录",
                              child: [],
                            },
                            {
                              id: 104,
                              name: "导出",
                              child: [],
                            },
                            {
                              id: 105,
                              name: "编辑",
                              child: [],
                            },
                            {
                              id: 106,
                              name: "管理",
                              child: [],
                            },
                            {
                              id: 110,
                              name: "历史账单",
                              child: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 157,
                  name: "收费设置",
                  child: [
                    {
                      id: 176,
                      name: "收费设置",
                      child: [
                        {
                          id: 179,
                          name: "基础费用消息通知设置",
                          child: [],
                        },
                        {
                          id: 180,
                          name: "非随基础费用缴费类消息通知设置",
                          child: [],
                        },
                        {
                          id: 181,
                          name: "基础费用减免设置",
                          child: [],
                        },
                        {
                          id: 182,
                          name: "优惠活动设置",
                          child: [],
                        },
                        {
                          id: 183,
                          name: "日常费用消息通知设置",
                          child: [],
                        },
                        {
                          id: 184,
                          name: "现金收款抹零设置",
                          child: [],
                        },
                        {
                          id: 111,
                          name: "商户能否修改缴费金额设置",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 177,
                      name: "日常收费设置",
                      child: [
                        {
                          id: 188,
                          name: "增加其它收费",
                          child: [],
                        },
                        {
                          id: 112,
                          name: "删除",
                          child: [],
                        },
                        {
                          id: 113,
                          name: "停用",
                          child: [],
                        },
                        {
                          id: 114,
                          name: "设置",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 178,
                      name: "合同收费项设置",
                      child: [
                        {
                          id: 189,
                          name: "新增收费项",
                          child: [],
                        },
                        {
                          id: 190,
                          name: "编辑",
                          child: [],
                        },
                        {
                          id: 115,
                          name: "启用/停用",
                          child: [],
                        },
                        {
                          id: 116,
                          name: "删除",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 158,
                  name: "收费凭证",
                  child: [
                    {
                      id: 192,
                      name: "凭证编号编译规则设置",
                      child: [],
                    },
                    {
                      id: 193,
                      name: "电子章上传",
                      child: [],
                    },
                  ],
                },
                {
                  id: 159,
                  name: "线下收费",
                  child: [
                    {
                      id: 194,
                      name: "新建收费",
                      child: [],
                    },
                    {
                      id: 195,
                      name: "详情",
                      child: [],
                    },
                  ],
                },
                {
                  id: 160,
                  name: "意向金",
                  child: [
                    {
                      id: 196,
                      name: "新增意向金",
                      child: [],
                    },
                    {
                      id: 197,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 198,
                      name: "退款",
                      child: [],
                    },
                    {
                      id: 199,
                      name: "作废",
                      child: [],
                    },
                    {
                      id: 200,
                      name: "记录",
                      child: [],
                    },
                    {
                      id: 201,
                      name: "缴费凭证",
                      child: [],
                    },
                    {
                      id: 2010,
                      name: "退款信息",
                      child: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 202,
              name: "投诉管理",
              child: [
                {
                  id: 203,
                  name: "投诉概览",
                  child: [],
                },
                {
                  id: 204,
                  name: "详细列表",
                  child: [
                    {
                      id: 205,
                      name: "待处理",
                      child: [
                        {
                          id: 207,
                          name: "导出",
                          child: [],
                        },
                        {
                          id: 208,
                          name: "详情",
                          child: [
                            {
                              id: 117,
                              name: "处理",
                              child: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: 206,
                      name: "已处理",
                      child: [
                        {
                          id: 209,
                          name: "导出",
                          child: [],
                        },
                        {
                          id: 210,
                          name: "详情",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: 212,
              name: "智能巡检",
              child: [
                {
                  id: 213,
                  name: "巡检计划表",
                  child: [
                    {
                      id: 218,
                      name: "新增计划",
                      child: [],
                    },
                    {
                      id: 219,
                      name: "更新",
                      child: [],
                    },
                    {
                      id: 220,
                      name: "删除",
                      child: [],
                    },
                  ],
                },
                {
                  id: 214,
                  name: "异常监管",
                  child: [
                    {
                      id: 221,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 223,
                      name: "卫生异常问题设置",
                      child: [],
                    },
                    {
                      id: 224,
                      name: "异常问题设置",
                      child: [],
                    },
                    {
                      id: 225,
                      name: "详情",
                      child: [],
                    },
                  ],
                },
                {
                  id: 118,
                  name: "巡检日志",
                  child: [
                    {
                      id: 119,
                      name: "导出",
                      child: [],
                    },
                    {
                      id: 120,
                      name: "异常问题查看",
                      child: [],
                    },
                  ],
                },
                {
                  id: 121,
                  name: "巡检设置",
                  child: [
                    {
                      id: 122,
                      name: "点位二维码",
                      child: [
                        {
                          id: 125,
                          name: "批量导出",
                          child: [],
                        },
                        {
                          id: 126,
                          name: "导出",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 123,
                      name: "点位设置",
                      child: [],
                    },
                    {
                      id: 124,
                      name: "平面图上传",
                      child: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 234,
              name: "财务管理",
              child: [
                {
                  id: 235,
                  name: "财务概览",
                  child: [],
                },
                {
                  id: 236,
                  name: "对账管理",
                  child: [
                    {
                      id: 127,
                      name: "线上账单",
                      child: [
                        {
                          id: 129,
                          name: "确认核销",
                          child: [],
                        },
                        {
                          id: 130,
                          name: "历史核销记录",
                          child: [
                            {
                              id: 131,
                              name: "取消核销",
                              child: [],
                            },
                            {
                              id: 132,
                              name: "异常信息",
                              child: [],
                            },
                            {
                              id: 133,
                              name: "详细数据",
                              child: [],
                            },
                          ],
                        },
                        {
                          id: 134,
                          name: "汇总核销",
                          child: [],
                        },
                      ],
                    },
                    {
                      id: 128,
                      name: "现金账单",
                      child: [
                        {
                          id: 135,
                          name: "确认核销",
                          child: [],
                        },
                        {
                          id: 136,
                          name: "历史核销记录",
                          child: [
                            {
                              id: 137,
                              name: "取消核销",
                              child: [],
                            },
                            {
                              id: 138,
                              name: "异常信息",
                              child: [],
                            },
                            {
                              id: 139,
                              name: "详细数据",
                              child: [],
                            },
                          ],
                        },
                        {
                          id: 140,
                          name: "汇总核销",
                          child: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 237,
                  name: "退款管理",
                  child: [
                    {
                      id: 242,
                      name: "设置",
                      child: [],
                    },
                    {
                      id: 244,
                      name: "查看凭证",
                      child: [],
                    },
                    {
                      id: 141,
                      name: "上传凭证",
                      child: [],
                    },
                    {
                      id: 142,
                      name: "处理",
                      child: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 245,
              name: "操作日志",
              child: [],
            },
            {
              id: 246,
              name: "操作员设置",
              child: [
                {
                  id: 247,
                  name: "操作员管理",
                  child: [
                    {
                      id: 249,
                      name: "新增",
                      child: [],
                    },
                    {
                      id: 250,
                      name: "联系人设置",
                      child: [],
                    },
                    {
                      id: 251,
                      name: "编辑",
                      child: [],
                    },
                    {
                      id: 252,
                      name: "重置密码",
                      child: [],
                    },
                    {
                      id: 253,
                      name: "删除",
                      child: [],
                    },
                  ],
                },
                {
                  id: 248,
                  name: "角色管理",
                  child: [
                    {
                      id: 254,
                      name: "新增",
                      child: [],
                    },
                    {
                      id: 255,
                      name: "编辑",
                      child: [],
                    },
                    {
                      id: 256,
                      name: "删除",
                      child: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 257,
              name: "系统设置",
              child: [
                {
                  id: 100,
                  name: "收款账号设置",
                  child: [],
                },
              ],
            },
          ],
        },
        {
          name: "APP",
          id: 456,
          child: [
            {
              name: "物业管理",
              id: 457,
              child: [
                {
                  name: "待处理合同",
                  id: 457,
                  child: [],
                },
                {
                  name: "日常费用出账确认",
                  id: 457,
                  child: [],
                },
                {
                  name: "商户信息",
                  id: 457,
                  child: [],
                },
                {
                  name: "费用监管",
                  id: 457,
                  child: [],
                },
                {
                  name: "市场数据",
                  id: 457,
                  child: [],
                },
                {
                  name: "巡检监控",
                  id: 457,
                  child: [],
                },
                {
                  name: "溯源信息",
                  id: 457,
                  child: [],
                },
                {
                  name: "日常巡检",
                  id: 457,
                  child: [],
                },
                {
                  name: "卫生处理",
                  id: 457,
                  child: [],
                },
                {
                  name: "异常监管",
                  id: 457,
                  child: [],
                },
                {
                  name: "抄表统计",
                  id: 457,
                  child: [],
                },
                {
                  name: "投诉管理",
                  id: 457,
                  child: [],
                },
                {
                  name: "卫生监管",
                  id: 457,
                  child: [],
                },
              ],
            },
            {
              name: "信发系统",
              id: 458,
              child: [],
            },
          ],
        },
      ],
      defaultProps: {
        children: "child",
        label: "name",
      },
      tableList: [],
      ruleForm: {
        name: "",
        sex: "",
        card_no: "",
        mobile: "",
        address: "",
        age: "",
        management_id: "",
        entry_time: "2020/01/01 00:00:00",
        avatar: "",
      },
      params: {
        name: "",
        sex: -1,
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        card_no: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        entry_time: [
          { required: true, message: "请选择入职时间", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入居住地址", trigger: "blur" },
        ],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        management_id: [
          { required: true, message: "请选择角色", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      settingVisible: false,
      planeImage: "",
      peoples: [
        {
          id: 136,
        },
      ],
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
      roleData: [],
      id: "",
      loading: false,
    };
  },
  created () {
    this.getList();
    this.getRole();
  },
  methods: {
    // TODO: 打印构造的数据结构
    printf () {
      console.log(this.data);
    },
    switchChange (data) {
      this.$request
        .HttpPost("/user/setProperty", {
          user_id: data.user_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
        });
    },
    initRu () {
      this.ruleForm = {
        name: "",
        sex: "",
        card_no: "",
        mobile: "",
        address: "",
        age: "",
        management_id: "",
        entry_time: "2020/01/01 00:00:00",
      };
    },
    currentChange (page) {
      this.pageNum = page;
      this.getList();
    },
    del (row) {
      this.$confirm("此操作将删除该员工，确认删除？", "提示", {
        type: "warning",
      }).then(() => {
        this.$request
          .HttpPost("/user/delete", { id: row.user_id })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.pageNum = 1;
            this.initRu();
            this.getList();
          });
      });
    },
    resetPass (user_id) {
      this.$confirm("重置密码后为联系电话后六位", "提示", {
        type: "warning",
      }).then(() => {
        this.$request.HttpPost("/user/resetPwd", { user_id }).then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
        });
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "/user/add";
          let params = this.ruleForm;
          if (this.id) {
            url = "/user/edit";
            params.user_id = this.id;
          }
          this.$request.HttpPost(url, params).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.dialogVisible = false;
            this.pageNum = 1;
            this.initRu();
            this.getList();
          });
        }
      });
    },
    editAdmin (item) {
      this.id = item.user_id;
      // console.log(Object.keys(this.ruleForm))
      // this.ruleForm = item
      Object.keys(item).forEach((key) => {
        this.ruleForm[key] = item[key];
      });
      this.dialogVisible = true;
    },
    addAdmin () {
      this.id = "";
      this.dialogVisible = true;
    },
    getRole () {
      this.$request
        .HttpPost("/management/list", {
          pageNum: 1,
          pageSize: 999999999,
        })
        .then((res) => {
          this.roleData = res.data.list;
        });
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpPost("/user/list", {
          ...this.params,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.loading = false;
          this.pageTotal = res.data.total;
          this.tableList = res.data.list || [];
          this.tableList.forEach((item) => {
            if (item.property_status == 0) {
              item.property_status = false;
            } else {
              item.property_status = true;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deletePeople (index) {
      this.peoples.splice(index, 1);
    },
    addPeople () {
      this.peoples.push({
        id: 2,
      });
    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.ruleForm.avatar =
            process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        });
      }
    },
    uploadSuccess () { },
    uploadError () {
      this.$plugin.notifyError("上传失败");
    },
    handleClose () {
      this.initRu();
      this.$refs.ruleForm.resetFields();
    },
    append (data) {
      this.$prompt("请输入name", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          const newChild = { id: id++, name: value, child: [] };
          if (!data.child) {
            this.$set(data, "child", []);
          }
          data.child.push(newChild);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    remove (node, data) {
      const parent = node.parent;
      const children = parent.data.child || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
